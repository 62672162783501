import React from "react";
import { styled } from "../../stitches.config";
import Link from "../../components/Link";
import { bodyLarge } from "../commonStyles/Fonts";
import Media from "../../components/common/Media";
import MicrophoneIcon from "../assets/icons/microphone-icon.svg";
import PlayerIconBackground from "../assets/icons/player-icon.svg";

import ResizableContainer from "../../helpers/ResizableContainer"

interface PhotoOnLeftBlock1x3Props {
    article?: any;
}
const PhotoOnLeftBlock1x3: React.FC<PhotoOnLeftBlock1x3Props> = ({
    article,
}) => {
    const isDesktop = window.innerWidth > 996;
    const partner = (article.partner ? <Partner>{article.partner}</Partner> : null);

    return (
        <Link to={article.url} data-tb-region-item>
            <ImageWrapper>
                {article.podcast && <PodcastIcon />}
                {article.player && <PlayerIcon />}
                {isDesktop ?
                    <Media
                        media={article.media}
                        src={article.backgroundImage}
                        preserveRatio={false}
                        aspectRatio={1.5}
                        height={72}
                        width={108}
                    />
                    :
                    <Media
                        media={article.media}
                        src={article.backgroundImage}
                        preserveRatio={false}
                        aspectRatio={1.5}
                        height={150}
                        width={224}
                    />}
                {partner}
            </ImageWrapper>
            {isDesktop ?
                <TextWrapper className={bodyLarge()}>
                    <ResizableContainer content={article.title} />
                </TextWrapper>
                :
                <MobileText className={bodyLarge()}>{article.title}</MobileText>
            }
        </Link>
    );
};

const ImageWrapper = styled("div", {
    float: "left",
    marginRight: 16,
    marginBottom: "12px",
    position: "relative",
    "@bp4": {
        marginBottom: 0,
    },
    "& img": {
        width: "100%",
        height: "100%",
    },
});

const PodcastIcon = styled("div", {
    width: "36px",
    height: "36px",
    background: "$primary",
    position: "absolute",
    top: "114px",
    left: 0,
    zIndex: 100,
    backgroundImage: `url("${MicrophoneIcon}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "20px",
    "@bp4": {
        width: "20px",
        height: "20px",
        top: "52px",
        backgroundSize: "12px",
    },
});
const PlayerIcon = styled("div", {
    width: "48px",
    height: "48px",
    position: "absolute",
    top: "51px",
    left: "88px",
    zIndex: 100,
    backgroundImage: `url("${PlayerIconBackground}")`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "48px",
    "@bp4": {
        width: "32px",
        height: "32px",
        top: "20px",
        left: "38px",
        backgroundSize: "32px",
    },
});

const TextWrapper = styled("div", {
    color: "$grayscale100",
    fontWeight: "600",
    maxHeight: "72px",
    overflow: "hidden",
    marginLeft: "16px",
});

const MobileText = styled("span", {
    maxHeight: "72px",
    overflow: "hidden",
    width: "100%",
    display: "block",
});

const Partner = styled("span", {
    display: 'block',
    position: 'absolute',
    left: '0',
    top: '0',
    padding: '0px 8px',
    backgroundColor: '#D8D8D8',
    color: '#8B8B8B',
    lineHeight: '16px',
    fontSize: '11px',
    zIndex: '4',
});

export default PhotoOnLeftBlock1x3;
