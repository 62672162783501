import React from "react";
import { styled } from "../../stitches.config";
import Link from "../../components/Link";
import { h3 } from "../commonStyles/Fonts";
import { bodyLarge } from "../commonStyles/Fonts";
import Block1x3 from "./Block1x3";
import { Block1x3Props } from "./Block1x3";

interface Block3x3Props {
  data: Array<Block1x3Props>;
}

const Block3x3: React.FC<Block3x3Props> = ({ data }) => {
  return (
    <StyledBlock3x3 >
      <div className="row">
        {data.map((articleBlock, index) => (
          <div key={articleBlock.header} className={"col-md-4 col-xs-12 block--" + index} data-tb-region={articleBlock.tbRegion}>
            <Block1x3 articles={articleBlock.articles} header={articleBlock.header} headerLink={articleBlock.headerLink} style={articleBlock.style} />
          </div>
        ))}
      </div>
    </StyledBlock3x3>
  );
};

const StyledBlock3x3 = styled("div", {
  marginTop: "0px",
  "@bp4": {
    marginTop: "40px",
  },
  "& .block--1": {
    marginTop: "40px",
    "@bp4": {
      marginTop: "0px",
    },
  },
  "& .block--2": {
    marginTop: "40px",
    "@bp4": {
      marginTop: "0px",
    },
  }
});

export default Block3x3;
